@import url('css/bootstrap.min.css');

@font-face {
  font-family: 'Circe-Regular';
  src: url('fonts/Circe-Regular.eot');
  src: url('fonts/Circe-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/Circe-Regular.woff2') format('woff2'),
  url('fonts/Circe-Regular.woff') format('woff'),
  url('fonts/Circe-Regular.ttf') format('truetype'),
  url('fonts/Circe-Regular.svg#Circe-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circe-Light';
  src: url('fonts/Circe-Light.eot');
  src: url('fonts/Circe-Light.eot?#iefix') format('embedded-opentype'),
  url('fonts/Circe-Light.woff2') format('woff2'),
  url('fonts/Circe-Light.woff') format('woff'),
  url('fonts/Circe-Light.ttf') format('truetype'),
  url('fonts/Circe-Light.svg#Circe-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Circe-Bold';
  src: url('fonts/Circe-Bold.eot');
  src: url('fonts/Circe-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Circe-Bold.woff2') format('woff2'),
  url('fonts/Circe-Bold.woff') format('woff'),
  url('fonts/Circe-Bold.ttf') format('truetype'),
  url('fonts/Circe-Bold.svg#Circe-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Circe-Regular', sans-serif;
  background: url(img/bg.png) center top / cover no-repeat;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  display: block;
  text-decoration: none;
  transition: all .4s;
}
a:hover {
  text-decoration: none;
}
a:focus,
button:focus,
input:focus {
  outline: none;
}
button {
  display: block;
  cursor: pointer;
  transition: all .4s;
}
img {
  display: block;
  max-width: 100%;
}
section {
  padding: 50px 0;
}
.slick-slide:focus {
  outline: none;
}
@mixin btn {
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(#0083fb, #0079e9);
  font-size: 18px;
  text-align: center;
  color: #fff;
  padding: 10px;
  border: none;
}
.page-header {
  background-color: #e8e8e8;
  padding: 15px 0;
}
.header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-menu {
  display: flex;
}
.nav-item {
  position: relative;

  &:first-child {
    margin-right: 25px;
  }
}
@mixin menuStyle {
  font-size: 18px;
  font-family: 'Circe-Light', sans-serif;
  color: #000;
  text-transform: uppercase;
}
.menu-link {
  @include menuStyle;
  &:hover + .submenu {
    display: block;
  }
}
.submenu {
  display: none;
  position: absolute;
  z-index: 5;
  left: 8px;
  top: 100%;
  width: 220px;
  color: #fff;
  background-color: #0079e9;
  border-top: 2px solid #fff;
  padding: 15px;
}
.top-btn {
  @include menuStyle;
  border-radius: 15px;
  background-color: transparent;
  border: 1px solid #0079e9;
  padding: 10px 15px;

  &:hover {
    background-color: #0079e9;
    color: #fff;
  }
}
.phone {
  font-size: 22px;
  color: #0079e9;
}
.fs-section {
  background: url(img/bg1.png) right top / contain no-repeat;
}
.text-block {
  margin-top: 30px;
}
.main-header {
  font-family: 'Circe-Bold', sans-serif;
  font-size: 52px;
  line-height: 1;
}
.main-subheader {
  font-family: 'Circe-Light', sans-serif;
  font-size: 30px;
}
.fs-advantages {
  margin: 25px 0;
  position: relative;
  left: -20px;
  display: flex;
  justify-content: space-between;
}
.adv-item {
  font-size: 20px;
  font-family: 'Circe-Light', sans-serif;
  text-align: center;
  line-height: 1.2;
}
.rep-card {
  margin-bottom: 25px;
  padding: 15px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 0 20px 5px rgba(0,0,0,.2);
  cursor: pointer;
  transition: all .4s;

  &:hover {
    background-color: #6daee1;
  }
}
.rep-img {
  height: 140px;
  margin: 0 auto;
}
.rep-text {
  margin-top: 5px;
  text-align: center;
  font-size: 20px;
}
.other-products {
  margin: 50px auto;
  padding: 10px;
  width: 260px;
  text-align: center;
  border: 1px solid #0079e9;
  color: #0079e9;
  border-radius: 15px;
  font-size: 20px;
  background-color: transparent;

  &:hover {
    background-color: #0079e9;
    color: #fff;
  }
}
.hidden-row {
  display: none;
  position: relative;
}
.hidden-prod {
  cursor: pointer;
}
.prod-text {
  text-align: center;
  font-family: 'Circe-Light', sans-serif;
}
.not-found {
  margin-top: 100px;
  margin-bottom: 30px;
  font-size: 20px;
  text-align: center;
}
.order-btn {
  margin: 0 auto;
}
.appreciate {
  margin: 50px 0 30px;
  font-size: 28px;
}
.go-header-wrapper {
  display: flex;
  align-items: flex-start;
}
.go-header-inner {
  display: flex;
  flex-direction: column;
}
.go-header {
  font-size: 28px;
  line-height: 1;
  display: table;
  margin: 0 auto 10px;
  & span {
    display: block;
    margin-bottom: 10px;
    font-family: 'Circe-Bold', sans-serif;
    font-size: 44px;
  }
}
.go-btn {
  margin: 0 auto;
}
.call-text {
  color: #5a5a5a;
  text-align: center;
  line-height: 1;
  & span {
    display: block;
    font-family: 'Circe-Bold', sans-serif;
    font-size: 24px;
  }
}
.cour-num {
  color: #586470;
  font-size: 250px;
  line-height: 1;
}
.why-item {
  width: 300px;
  position: absolute;


  &.why-item-left .why-item-header::before {
      left: -60px;
  }
  &.why-item-right .why-item-header {
    text-align: right;

    &::before {
      right: -60px;
    }
  }
  &:first-of-type {
    top: 30px;
    left: 80%;
  }
  &:nth-of-type(2) {
    top: 210px;
    left: 103%;
  }
  &:nth-of-type(3) {
    bottom: 210px;
    left: 103%;
  }
  &:nth-of-type(4) {
    bottom: 30px;
    left: 80%;
  }
  &:nth-of-type(5) {
    bottom: 30px;
    right: 80%;
  }
  &:nth-of-type(6) {
    bottom: 210px;
    right: 103%;
  }
  &:nth-of-type(7) {
    top: 210px;
    right: 103%;
  }
  &:nth-of-type(8) {
    top: 30px;
    right: 80%;
  }
}
.why-item-header {
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background: url(img/cross.png) center / contain no-repeat;
    cursor: pointer;
  }
  &:hover + .why-hidden-item {
    opacity: 1;
  }
  &:hover::before {
    background: url(img/cross-hover.png) center / contain no-repeat;
  }
}

.why-img-wrapper {
  display: table;
  position: relative;
  margin: 100px auto 0;
}
.why-img {

}
.why-item-header {
  font-family: 'Circe-Bold', sans-serif;
  font-size: 18px;
}
.why-hidden-item {
  position: absolute;
  top: -20px;
  left: 0;
  display: table;

  opacity: 0;
  transition: all .4s;

  
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    border: 10px solid transparent;

  }
  &.why-right::after {
    left: -25px;
    border-right: 20px solid #9ecefa;
  }
  &.why-left::after {
    right: -25px;
    border-left: 20px solid #9ecefa;
  }
}
.why-hidden-wrapper {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 5px 1px rgba(0,0,0,.2);
}
.why-text {
  font-size: 18px;
  padding: 10px;
  line-height: 1.2;
  background-color: #fff;
}
.why-header {
  padding: 10px;
  font-size: 22px;
  color: #fff;
  background-color: #9ecefa;
}
.call-btn {
  @include btn;
  width: 260px;
  border-radius: 10px;

}
.call-btn::after,
.call-form-text::after {
  content: '';
  width: 200px;
  height: 200px;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background:rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  transform:scale(0);
  z-index: 20;
  animation:ripple 4s infinite;
}
@keyframes ripple {
  0% {opacity: 1; transform: scale(0);}
  25% {opacity: 0; transform: scale(2.5);}
  100% {opacity: 0; transform: scale(2.5);}
}
.personnel-slider .stories-arrow,
.discount-slider .stories-arrow,
.rep-slider .stories-arrow {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
}
.personnel-slider .prev-arrow,
.discount-slider .prev-arrow,
.rep-slider .prev-arrow {
  left: 0;
}
.personnel-slider .next-arrow,
.discount-slider .next-arrow,
.rep-slider .next-arrow {
  right: 0;
}
.pers-slide {
  display: flex;
}
.pers-img-wrapper {
  position: relative;
}
.pers-desc {
  position: absolute;
  top: 30px;
  left: -100px;
  width: 250px;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  line-height: 1.2;
  box-shadow: 0 0 30px 2px rgba(0,0,0,.2);
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    right: -35px;
    top: 10px;
    border: 20px solid transparent;
    border-left: 25px solid #fff;
  }
}
.pers-name {
  margin-bottom: 10px;
  font-family: 'Circe-Bold', sans-serif;
  font-size: 20px;
  color: #7e7e7e;
}
.slide-header {
  margin-top: 40px;
  margin-bottom: 100px;
  font-size: 40px;
  line-height: 1;
}
.call-form,
.call-input-wrapper {
  display: flex;
}
.call-input-wrapper {

  border: 1px solid #b1b1b1;
  font-size: 14px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.call-format {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.call-input {
  width: 200px;
  padding: 15px 15px 15px 0;
  border: none;
}
.call-form-text {
  @include btn;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}
.callback {
  margin-top: 30px;
  font-size: 20px;
  color: #707070;

  & span {
    font-family: 'Circe-Bold', sans-serif;
  }
}
.discount-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 20px 2px rgba(0,0,0,.3);
  text-align: center;
}
.disc-img {
  margin: 0 auto;
}
.discount-header {
  margin-bottom: 10px;
  font-family: 'Circe-Bold', sans-serif;
  font-size: 26px;
}
.discount-subheader {
  font-size: 18px;
}
.standart-text {
  margin-top: 40px;
  font-size: 18px;
}
.percent {
  font-family: 'Circe-Bold', sans-serif;
  font-size: 60px;
  color: #586470;
}
.discount-btn {
  margin: auto;
}
.serts-section,
.team-img {
  position: relative;
  top: -100px;
}
.serts-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.team-img-wrapper {
  position: relative;
}

.standard-label {
  position: relative;
  display: table;
  padding: 20px;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  font-family: 'Circe-Bold', sans-serif;
  background-color: #9ecefa;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    border: 15px solid transparent;
    border-right: 15px solid #9ecefa;
  }
}
.label-header {
  font-size: 22px;
}
.team-label {
  position: absolute;
  right: 0;
  bottom: 115px;
}
.serts-header {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
  color: #586470;
}
.serts {
  display: flex;
  justify-content: center;
}
.sert-text {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}
.sert {
  margin: 0 5px;
  height: 200px;
}

.audio-btn {
  margin-top: 20px;
  padding: 10px 10px 10px 40px;
  background: url(img/audio.png) 30px center no-repeat #0079e9;
}
.stories-slider-wrapper {
  position: relative;
}
.stories-btns,
.personnel-btns,
.appliances-btns {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.stories-arrow {
  width: 50px;
  height: 50px;
  border: none;
}
.prev-arrow {
  background: url(img/arrow-l.png) center / contain no-repeat;
}
.next-arrow {
  background: url(img/arrow-r.png) center / contain no-repeat;
}
.stories-subheader {
  margin-bottom: 50px;
  font-size: 23px;
  text-align: center;
  color: #9d9d9d;
}
.stories-slide {
  display: flex;
}
.item-name {
  width: 410px;
  position: relative;
  top: -30px;
  left: 50%;
  margin-left: -205px;
  color: #0079e9;
  font-size: 24px;
  text-align: center;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 20px 2px rgba(0,0,0,.2);
}
.diagnosis {
  margin-left: 30px;
}
.diag-item {
  margin-bottom: 15px;
  font-size: 22px;
}
.diag-header,
.diag-text {
  line-height: 1;
}
.diag-header {
  font-family: 'Circe-Bold', sans-serif;
}
.diag-text {
  font-family: 'Circe-Light', sans-serif;
}
.repair-price {
  font-family: 'Circe-Bold', sans-serif;
  color: #0079e9;
}
.slick-dots li button:before {
  font-size: 15px;
}
.slick-dots li.slick-active button:before,
.slick-dots li button:hover:before{
  color: #0079e9;
}
.section-header {
  margin-bottom: 50px;
  font-size: 28px;
  text-align: center;
}
.contact-wrapper,
.contact-item {
  display: flex;
}
.contact-wrapper {
  justify-content: space-between;
  margin-bottom: 20px;
}
.contact-item {
  align-items: center;
  font-size: 20px;
  line-height: 1;
}
.contact-img {
  margin-right: 10px;
}
.contacts-phone {
  margin-bottom: 10px;
  color: #000;
  &:hover {
    color: #000;
  }
}
.footer-inner {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.privacy-link {
  margin-top: 15px;
  color: #000;

  &:hover {
    color: #000;
  }
}
@media screen and (max-width: 1300px) {
  .why-item {
    width: 200px;
  }
}
@media screen and (max-width: 1199px) {
  .serts-section, .team-img {
    top: 0;
  }
  .team-label {
    right: -45px;
    bottom: 30px;
  }
  .item-name {
    width: auto;
    display: table;
    margin: auto;
    left: 0;
    font-size: 20px;
  }
  .discount-btn {
    width: 100%;
  }
  .pers-desc {
    left: -130px;
    width: 220px;
  }
  .rep-text {
    font-size: 18px;
  }
  .why-section {
    display: none;
  }
  .fs-section {
    background: url(img/bg1.png) right top/ 800px no-repeat;
  }
}
@media screen and (max-width: 991px) {
  .fs-section {
    background: url(img/bg1.png) right top/ 600px no-repeat;
  }
  .text-block {
    margin-top: 200px;
  }
  .main-menu {
    display: none;
  }
  .appreciate {
    text-align: center;
  }
  .go-header-wrapper {
    justify-content: center;
  }
  .slide-left {
    display: none;
  }
  .pers-img-wrapper {
    display: flex;
    flex-direction: column;
  }
  .slide-right {
    margin: auto;
  }
  .pers-img {
    max-height: 500px;
  }
  .pers-desc {
    order: 2;
    position: relative;
    top: -50px;
    left: 0;
    margin: auto;
    text-align: center;

    &::after {
      display: none;
    }
  }
  .discount-card {
    width: 85%;
    margin: 15px auto;
  }
  .stories-slide {
    flex-direction: column;
    width: 85%;
    margin: 0 auto;
  }
  .slide-img,
  .courier-img,
  .standart-img {
    margin: 0 auto;
  }
  .contact-item {
    font-size: 17px;
  }
  .contact-img {
    width: 120px;
  }
  .team-label {
    bottom: 10px;
    right: 50%;
    transform: translateX(50%);

    &::after {
      display: none;
    }
  }
  .serts-header {
    margin-top: 20px;
  }
  .personnel-call-form {
    justify-content: center;
    margin-bottom: 20px;
  }
  .diagnosis {
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .rep-card {
    width: 80%;
    margin: 15px auto;
  }
  .other-products {
    display: none;
  }
  .rep-img {
    height: auto;
  }
  .top-btn {
    display: none;
  }
  .fs-section {
    background: url(img/bg1-mobile.png) center -100px / 600px no-repeat;
  }
  .main-header,
  .main-subheader {
    text-align: center;
  }
  .call-input-wrapper,
  .call-form-text {
    width: 50%;
  }
  .call-input {
    width: auto;
    flex-grow: 1;
  }
  .fs-advantages {
    position: static;
    margin: 25px auto;
  }
  .contact-wrapper {
    flex-direction: column;
  }
  .label-first {
    right: initial;
    left: 50%;
    transform: translateX(-50%);
  }
  .stories-slide {
    width: 100%;
  }
  .diagnosis {
    margin-top: 60px;
  }
  .stories-btns {
    top: 45%;
  }
}
@media screen and (max-width: 575px) {
  .fs-section {
    background: url(img/bg1-mobile.png) center -100px / 100% no-repeat;
  }
  section {
    padding: 30px 0;
  }
  .call-input-wrapper,
  .call-form-text {
    border-radius: 10px;
    overflow: hidden;
  }
  .call-input-wrapper {
    margin-bottom: 15px;
  }
  .call-input {
    padding: 10px 10px 10px 0;
  }
  .call-form-text {
    font-size: 16px;
  }
  .call-form {
    flex-direction: column;
    align-items: center;
  }
  .not-found {
    margin-top: 40px;
  }
  .personnel-slider .stories-arrow {
    top: 70%;
  }
  .discount-card,
  .rep-card {
    width: 75%;
  }
  .discount-slider .prev-arrow,
  .rep-slider .prev-arrow {
    left: 15px;
  }
  .discount-slider .next-arrow,
  .rep-slider .next-arrow {
    right: 15px;
  }
  .standard-label {
    padding: 10px;

  }
  .label-header {
    font-size: 18px;
  }
  .section-header {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 479px) {
  .main-header {
    font-size: 40px;
  }
  .main-subheader {
    font-size: 25px;
  }
  .call-input-wrapper, .call-form-text {
    width: 100%;
  }
  .stories-arrow {
    width: 40px;
    height: 40px;
  }
  .phone {
    font-size: 17px;
  }
  .logo {
    max-width: 190px;
  }
  .appreciate {
    margin-top: 0;
    font-size: 20px;
  }
  .cour-num {
    font-size: 220px;
  }
  .discount-card {
    padding: 15px;
  }
  .discount-header {
    font-size: 20px;
  }
  .discount-subheader {
    font-size: 16px;
  }
  .section-header {
    font-size: 22px;
  }
  .adv-item {
    font-size: 16px;
  }
  .diag-item,
  .item-name {
    font-size: 18px;
  }
  .stories-subheader {
    font-size: 18px;
  }
  .item-name {
    padding: 5px;
  }
}
@media screen and (max-width: 380px) {
  .fs-section {
    background: url(img/bg1-mobile.png) center top /100% no-repeat;
  }
  .main-header {
    font-size: 30px;
  }
  .main-subheader {
    font-size: 22px;
  }
  .go-header {
    font-size: 22px;
  }
  .cour-num {
    font-size: 175px;
  }
  .call-btn {
    width: 100%;
  }
  .item-name {
    font-size: 16px;
    top: -20px;
  }
  .diagnosis {
    margin-top: 40px;
  }
  .stories-btns {
    top: 40%;
  }
  .phone {
    font-size: 14px;
  }
  .logo {
    max-width: 150px;
  }
  .discount-slider .prev-arrow, .rep-slider .prev-arrow {
    left: 0;
  }
  .discount-slider .next-arrow, .rep-slider .next-arrow {
    right: 0;
  }
  .contact-img {
    width: 90px;
  }
}

